@font-face {
    font-family: "Carme";
    src: url("../../assets/fonts/Carme-Regular.ttf");
}

@font-face {
    font-family: "Reenie";
    src: url("../../assets/fonts/ReenieBeanie-Regular.ttf");
}


@keyframes appears {
    from {
        color: transparent;
    }

    to {
        color: white;
    }
}

@keyframes logo {
    from {
        top: 50%;
    }

    to {
        top: calc(50% - 203px);
    }
}

.logoBanner {
    width: 100%;
    height: 60px;
    background-color: black;
    text-align: center;
    display: none;
    align-items: center;
    justify-content: center;
}

.logoSmall {
    width: 48px;
}

.welcome {
    width: 100%;
    height: 100vh;
}

.sectionBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.sectionBox a {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.sectionBox h2 {
    font-size: 40px;
    margin-top: 0px;
    animation-name: appears;
    animation-duration: 2s;
    font-family: "Akira";
}

.sectionBox p {
    font-size: 20px;
    font-family: "Jakarta";
    margin: 0px 0;
    line-height: 28px;
    animation-name: appears;
    animation-duration: 2s;

}

.section {
    width: 600px;
    flex-grow: 1;
    height: calc((100vh - 34px)/2);
    flex-grow: 1;
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    transition: all 1s ease;
}

.section h2 {
    transition: all 1s ease;
}

.section p {
    color: white;
}

.section:hover h2 {
    color: #D0012A;
}


.banner {
    width: 100%;
    min-height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    position: fixed;
    bottom: 0px;
}

.banner a {
    color: #000;
}


.banner marquee {
    margin: 0px;
    font-family: "Reenie";
    font-size: 30px;
}

.logo {
    position: absolute;
    top: calc(50% - 203px);
    left: calc(50% - 159px);
    animation-name: logo;
    animation-duration: 8s;
    animation-delay: -4s;
}

.nav {
    width: 100%;
}

.logoNav {
    width: 80px;
    position: absolute;
    top: 48px;
    left: calc(50% - 40px);
    position: fixed;
    z-index: 1;
}

.divider {
    width: 100%;
    height: 144px;
    background-color: #D0012A;
}

@media(max-width: 1024px) {
    .logoBanner {
        display: flex;
    }

    .logo {
        display: none;
    }
}
@keyframes move {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.3)
    }

    100% {
        transform: scale(1);
    }
}

.agencySection {
    width: 100%;
    height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 80px;
    position: absolute;
    top: 48px;
    left: calc(50% - 40px)
}

.borderedDiv {
    border: 5px solid white;
    width: 100%;
    height: 30%;
    position: relative;
    max-width: calc(1440px - 10%);
    transition: all 1s ease;
    cursor: pointer;
}


.line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 0px;
    background-color: #D0012A;
    transition: all 1s ease;
}

.borderedDiv .left:hover .line,
.borderedDiv .right:hover .line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 100%;
}

.bottomLeft:hover .line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 100%;
}

.borderedDiv .right {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    right: -18px;
    top: -35px;
}

.borderedDiv .right.middleRight {
    right: -80x;
}

.borderedDiv .centered {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
}

.borderedDiv .bottom {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: 50%;
    bottom: -20%;
    transform: translate(-50%);
}


.borderedDiv .bottom.bottomLeft {
    left: 25%;
}

.borderedDiv:hover {
    border: 5px solid #D0012A;
}

.managementSection {
    width: 100%;
    min-height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 10% 5%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-family: "Akira";
    font-size: 36px;
    text-transform: uppercase;
}

.text {
    font-family: "Carme";
}

a {
    text-decoration: none;
    color: white;
}

.managers {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 80px;
}

.managers img {
    width: 400px;
    height: 200px;
    margin: 0 0 48px 0;
}

.directorsSection {
    width: 100%;
    height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5% 10%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.directors {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    font-size: 24px;
    font-weight: bold;
    margin-top: 0px;
}

.directors div {
    margin: 0 24px 24px 0px;
}

.directors img {
    width: 400px;
    height: 500px;
}

.navBar {
    width: 100%;
    height: 180px;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    box-sizing: border-box;
    font-family: "Akira";
    font-size: 14px;
    background-color: transparent;
    z-index: 1;
}

.navBar img {
    width: 80px;
}

.scroll {
    margin-top: 24px;
}

.scroll img {
    animation-name: move;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

.title {
    font-family: "Akira";
    font-size: 36px;
    text-transform: uppercase;
}

.subtitle {
    font-family: "Jakarta";
    font-size: 20px;
    line-height: 28px;
}

.creativeBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.creativeBox img {
    width: 180px;
    height: 270px;
    margin: 0 0 24px 0;
}

.btn {
    width: 320px;
    height: 80px;
    background-color: transparent;
    border: 4px solid #D0012A;
    color: white;
    border-radius: 4px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Jakarta";
    transition: all 1s ease;
    cursor: pointer;
}

.btn:hover {
    background-color: #D0012A;
    color: white;
}

@media(max-width:2560px) {

    .borderedDiv .bottom {
        bottom: -9%;
    }

}

@media(max-width:1840px) {

    .borderedDiv .bottom {
        bottom: -20%;
    }

}

@media(max-width:1024px) {
    .managementSection {
        padding-top: 15%;
    }

    .borderedDiv .bottom {
        bottom: -16%;
    }

    .borderedDiv .left,
    .borderedDiv .right,
    .borderedDiv .centered,
    .borderedDiv .bottom {
        font-size: 24px;
    }
}

@media(max-width: 800px) {

    .borderedDiv .left,
    .borderedDiv .right,
    .borderedDiv .centered,
    .borderedDiv .bottom {
        font-size: 24px;
    }

    .borderedDiv .right {
        right: 5%;
    }

    .managementSection {
        padding: 120px 5% 80px 5%;
    }

    .bottomLeft:hover .line {
        width: 100%;
    }

    .directorsSection {
        height: auto;
    }

    /* .borderedDiv .bottomLeft {
        bottom: -15%;
    } */



}

@media(max-width: 600px) {
    .navBar {
        height: 80px;
    }

    .title {
        font-size: 24px;
    }

    .managementSection {
        padding-top: 120px;
    }

    .subtitle {
        font-size: 16px;
    }

    .directorsSection {
        padding: 5%;
    }

    .navBar img {
        width: 40px;
    }

    .managers img {
        width: 90%;
        height: auto;
    }

    .bottomLeft {
        bottom: 55%;
    }

    .bottomLeft:hover .line {
        width: 60%;
    }


}
@font-face {
    font-family: "Akira";
    src: url("../../assets/fonts/Akira\ Bold.ttf");
}

@font-face {
    font-family: "Silk";
    src: url("../../assets/fonts/SilkRemington-SBold.ttf");
}

@font-face {
    font-family: "Jakarta";
    src: url("../../assets/fonts/PlusJakartaSans-Light.ttf");
}


@keyframes move {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.3)
    }

    100% {
        transform: scale(1);
    }
}

.whoSection {
    width: 100%;
    height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 80px;
    position: absolute;
    top: 48px;
    left: calc(50% - 40px)
}

.borderedDiv {
    border: 5px solid white;
    width: 100%;
    height: 30%;
    position: relative;
    max-width: calc(1440px - 10%);
    transition: all 1s ease;
    cursor: pointer;
}

.borderedDiv .left {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: -18px;
    top: -35px;
}

.line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 0px;
    background-color: #D0012A;
    transition: all 1s ease;
}

.borderedDiv .left:hover .line,
.borderedDiv .right:hover .line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 100%;
}

.bottom:hover .line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 100%;
}

.borderedDiv .right {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    right: -18px;
    top: -35px;
}

.borderedDiv .right.middleRight {
    right: -80x;
}

.borderedDiv .centered {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
}

.borderedDiv .bottom {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: 50%;
    bottom: -20%;
    transform: translate(-50%);
}

.borderedDiv .bottom.bottomLeft {
    left: 30%;
}

.borderedDiv:hover {
    border: 5px solid #D0012A;
}

/* //MISSION// */

.missionSection {
    width: 100%;
    height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5% 20%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-family: "Akira";
    font-size: 36px;
    text-transform: uppercase;
}

.subtitle {
    /* font-family: "Silk"; */
    font-family: "Jakarta";

    font-size: 20px;
    line-height: 28px;
}

.text {
    font-family: "Akira";

}

a {
    text-decoration: none;
    color: white;
}


/* TEAM */
.teamSection {
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    color: white;
    text-align: center;
    padding: 5%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.serSection {
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    color: white;
    text-align: center;
    padding: 5%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.teamBox {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.founderCard {
    width: 50%;
    height: auto;
    flex-grow: 1;
    display: flex;
    padding: 24px;
    flex-wrap: wrap;
    box-sizing: border-box;
    /* font-family: "Silk"; */
    font-family: "Jakarta";

}

.founderCard h3 {
    font-family: "Silk";
    text-transform: uppercase;
}

.founderCard p {
    text-align: left;
    margin-top: 0px;
    line-height: 24px;
}

.founderCard div {
    width: 50%;
    padding: 0 2%;
    box-sizing: border-box;
}

.founderCard img {
    width: 100%;
}


/* SERVICES */
.servicesBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

.servicesBox p {
    width: 600px;
    flex-grow: 1;
    font-family: "Akira";
    font-size: 30px;
    padding: 0 10%;
    margin: 80px 0;
    box-sizing: border-box;

}

.serviceDetail {
    width: 100%;
    min-height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 10% 5% 5% 5%;
    box-sizing: border-box;

}

.serviceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    /* font-family: "Silk"; */
    font-family: "Jakarta";

    line-height: 28px;
    margin-bottom: 48px;
    margin-top: 48px;
}

.serviceHeader div {
    width: 700px;
    text-align: left;
    padding-right: 4%;
    box-sizing: border-box;
}

.btn {
    width: 320px;
    height: 80px;
    background-color: transparent;
    border: 4px solid #D0012A;
    color: white;
    border-radius: 4px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Jakarta";
    transition: all 1s ease;
    cursor: pointer;
}

.btn:hover {
    background-color: #D0012A;
    color: white;
}

.serviceContent {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.serviceContent img {
    width: 300px;
    height: 300px;
    margin: 24px 24px 0 0;
}


.navBar {
    width: 100%;
    height: 180px;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    box-sizing: border-box;
    font-family: "Akira";
    font-size: 14px;
    background-color: transparent;
    z-index: 1;
}

.navBar img {
    width: 80px;
}

.scroll {
    margin-top: 100px;
}

.scroll img {
    animation-name: move;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

span {
    font-family: "Akira";

}


@media(max-width:1024px) {
    .teamSection {
        flex-wrap: wrap;
        height: auto;
    }

    .founderCard {
        width: 100%;
        flex-wrap: nowrap;

    }

    .founderCard div {
        width: 100%;

    }

    .founderCard p {
        text-align: center;

    }

    .servicesBox {
        justify-content: center;
    }

    .servicesBox p {
        width: 400px;
    }

    .serSection {
        padding-top: 120px;
    }

    .teamBox {
        flex-wrap: nowrap;
        align-items: flex-start;
    }

    .serviceDetail {
        padding-top: 15%;
    }

    .teamSection {
        padding-top: 80px;
        padding-bottom: 80px;
        height: 100%;
    }

    .founderCard {
        font-size: 14px;
        padding: 24px 0;

    }

    .founderCard div {
        padding: 0%;
    }

}

@media(max-width:2560px) {

    .borderedDiv .bottom {
        bottom: -9%;
    }

}

@media(max-width:1840px) {

    .borderedDiv .bottom {
        bottom: -20%;
    }

}

@media (max-width:1400px) {
    .servicesBox p {
        width: 500px;
        /* background-color: red; */
    }

}

@media (max-width:1140px) {
    .servicesBox p {
        width: 400px;
    }

}

@media(max-width:1024px) {

    .borderedDiv .bottom {
        bottom: -16%;
    }

    .borderedDiv .left,
    .borderedDiv .right,
    .borderedDiv .centered,
    .borderedDiv .bottom {
        font-size: 24px;
    }
}

@media(max-width:900px) {
    .servicesBox p {
        width: 340px;
    }


}

@media(max-width: 800px) {
    .navBar {
        height: 80px;
    }

    .title {
        font-size: 24px;
    }

    .missionSection {
        padding: 5%;
    }

    .servicesBox p {
        width: 100%;
        font-size: 18px;
        margin: 70px 0;
        padding: 0 5%;

    }

    .servicesBox a {
        width: 100%;
    }

    .serviceDetail {
        padding-top: 120px;
        padding-bottom: 80px;
        text-align: center;
    }

    .serviceHeader {
        flex-wrap: wrap;
        justify-content: center;
    }

    .serviceHeader div {
        width: 700px;
        text-align: left;
    }

    .serviceHeader p {
        margin-top: 0px;
        text-align: center;
    }

    .serviceContent {

        justify-content: center;
    }

    .serviceContent img {
        margin: 12px;
    }

    .navBar img {
        width: 40px;
    }

    .teamBox {
        flex-wrap: wrap;
        align-items: center;
    }

}

@media(max-width: 600px) {
    .founderCard {
        flex-wrap: wrap;
        height: 600px;
    }

    .teamSection {
        height: auto;
    }
}

@media(max-width: 425px) {
    .founderCard {
        height: 700px;
    }
}
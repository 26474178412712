.whoSection {
    width: 100%;
    height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 80px;
    position: absolute;
    top: 48px;
    left: calc(50% - 40px)
}

.borderedDiv {
    border: 5px solid white;
    width: 100%;
    height: 30%;
    position: relative;
    max-width: calc(1440px - 10%);
    transition: all 1s ease;
    cursor: pointer;
}

.borderedDiv .left {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: -18px;
    top: -35px;
}

.line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 0px;
    background-color: #D0012A;
    transition: all 1s ease;
}

.borderedDiv .left:hover .line,
.borderedDiv .right:hover .line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 100%;
}

.bottom:hover .line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 100%;
}

.borderedDiv .right {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    right: -18px;
    top: -35px;
}

.borderedDiv .right.middleRight {
    right: -80x;
}

.borderedDiv .centered {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
}

.borderedDiv .bottom {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: 50%;
    bottom: -20%;
    transform: translate(-50%);
}


.borderedDiv .bottom.bottomLeft {
    left: 30%;
}

.borderedDiv:hover {
    border: 5px solid #D0012A;
}


.sectionDetail {
    width: 100%;
    min-height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 10% 5% 5% 5%;
    box-sizing: border-box;
}

.title {
    font-family: "Akira";
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 80px;
}

.btn {
    width: 320px;
    height: 80px;
    background-color: transparent;
    border: 4px solid #D0012A;
    color: white;
    border-radius: 4px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Jakarta";
    /* font-family: "Silk"; */
    cursor: pointer;
}

.navBar {
    width: 100%;
    height: 180px;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    box-sizing: border-box;
    font-family: "Akira";
    font-size: 14px;
    background-color: transparent;
    z-index: 1;
}

.navBar img {
    width: 80px;
}

.box {
    display: flex;
    /* justify-content: flex-start; */
    justify-content: space-around;
    margin-bottom: 48px;
}

.boxInfo {
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
}

.box img {
    width: 480px;
    margin-bottom: 48px;
}

.boxText {
    text-align: left;
    width: 600px;
    font-size: 16px;
    font-family: "Jakarta";
    padding-right: 16px;
    box-sizing: border-box;
}

.box h3 {
    font-size: 20px;
    font-family: "Akira";
    text-transform: uppercase;

}

.gallery {
    margin: 80px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.gallery img {

    width: 300px;
    margin: 0 24px 24px 0
}

.serviceDetail {
    width: 100%;
    min-height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 10% 5% 5% 5%;
    box-sizing: border-box;

}

.serviceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    /* font-family: "Silk"; */
    font-family: "Jakarta";

    line-height: 28px;
    margin-bottom: 48px;
    margin-top: 48px;

}

.serviceHeader div {
    width: 700px;
    text-align: left;
    padding-right: 4%;
    box-sizing: border-box;
}

.btn {
    width: 320px;
    height: 80px;
    background-color: transparent;
    border: 4px solid #D0012A;
    color: white;
    border-radius: 4px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Jakarta";
    /* font-family: "Silk"; */
    transition: all 1s ease;
    cursor: pointer;
}

.btn:hover {
    background-color: #D0012A;
    color: white;
}

.serviceContent {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.serviceContent img {
    width: 300px;
    height: 300px;
    margin: 24px 24px 0 0;
}

.txt {
    font-size: 20px;
    font-family: "Jakarta";

    text-transform: uppercase;
}

@media(max-width:2560px) {

    .borderedDiv .bottom {
        bottom: -9%;
    }

}

@media(max-width:1840px) {

    .borderedDiv .bottom {
        bottom: -20%;
    }


}

@media(max-width: 1440px) {
    .box img {
        width: 300px;
    }


    .boxText h3 {
        margin-top: 0px;

    }
}

@media(max-width: 1024px) {

    .sectionDetail,
    .serviceDetail {
        padding-top: 15%;
    }

    .borderedDiv .bottom {
        bottom: -16%;
    }

    .borderedDiv .left,
    .borderedDiv .right,
    .borderedDiv .centered,
    .borderedDiv .bottom {
        font-size: 24px;
    }

    .box img {
        width: 360px;
    }

    .boxInfo {
        width: 400px;
        padding-left: 0px;
    }

    .boxText {
        width: 400px;
        padding-left: 0px;
    }

    .boxText h3 {
        margin-top: 0px;

    }

    .gallery img {
        width: 200px;
    }
}



@media(max-width: 800px) {

    .borderedDiv .left,
    .borderedDiv .right,
    .borderedDiv .centered,
    .borderedDiv .bottom {
        font-size: 24px;
    }

    .box {
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 5%;
    }

    .box img {
        width: 100%;
    }

    .boxInfo {
        justify-content: center;
        width: 100%;
    }

    .boxText {
        text-align: center;
        padding-right: 0px;
    }

    .navBar {
        height: 80px;
    }

    .title {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .sectionDetail {
        padding-top: 100px;
        padding-bottom: 80px;
    }

    .btn {
        margin-top: 24px;
    }

    .serviceDetail {
        padding-top: 100px;
        padding-bottom: 40px;
    }

    .serviceHeader {
        flex-wrap: wrap;
        justify-content: center;
    }

    .serviceHeader div {
        width: 700px;
        text-align: left;

    }

    .serviceHeader p {
        margin-top: 0px;
        text-align: center;

    }

    .navBar img {
        width: 40px;
    }

    .gallery {
        margin-top: 48px;
        justify-content: center;
    }

    .gallery img {
        width: 300px;
        margin: 12px;
    }

    .serviceContent {

        justify-content: center;
    }

    .serviceContent img {
        margin: 12px;
    }

    .box img {
        width: 320px;
    }

    .txt {
        margin-top: 80px;
        font-size: 16px;
    }

}

@media(max-width: 350px) {
    .box img {
        width: 80%;
    }

    .gallery img {
        width: 80%;
    }
}
@font-face {
    font-family: "Carme";
    src: url("../../assets/fonts/Carme-Regular.ttf");
}

.recordSection {
    width: 100%;
    height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 80px;
    position: absolute;
    top: 48px;
    left: calc(50% - 40px)
}

.borderedDiv {
    border: 5px solid white;
    width: 100%;
    height: 30%;
    position: relative;
    max-width: calc(1440px - 10%);
    transition: all 1s ease;
    cursor: pointer;
}

.borderedDiv .left {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: -18px;
    top: -35px;
}

.line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 0px;
    background-color: #D0012A;
    transition: all 1s ease;
}

.borderedDiv .left:hover .line,
.borderedDiv .right:hover .line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 100%;
}

.bottom:hover .line {
    height: 4px;
    border: none;
    margin-top: 0px;
    width: 100%;
}

.borderedDiv .right {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    right: -18px;
    top: -35px;
}

.borderedDiv .right.middleRight {
    right: -80x;
}

.borderedDiv .centered {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
}

.borderedDiv .bottom {
    color: white;
    text-transform: uppercase;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    background: #000;
    padding: 10px;
    position: absolute;
    left: 50%;
    bottom: -20%;
    transform: translate(-50%);
}


.borderedDiv .bottom.bottomLeft {
    left: 30%;
}

.borderedDiv:hover {
    border: 5px solid #D0012A;
}

.title {
    font-family: "Akira";
    font-size: 36px;
    text-transform: uppercase;
}

.text {
    font-family: "Carme";
}

a {
    text-decoration: none;
    color: white;
}

.digitalSection {
    width: 100%;
    min-height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5%;
    box-sizing: border-box;
    padding-top: 10%;
}

.imgsBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.image {
    width: 300px;
    height: 300px;
    margin: 12px;
    background-position: center;
    background-size: cover;
}

.cover {
    width: 100%;
    height: 100%;
    background-color: rgba(91, 91, 91, .8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
    transition: all 1s ease;
}

.btn {
    width: 140px;
    height: 42px;
    background-color: transparent;
    border: 4px solid #D0012A;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    font-family: "Jakarta";
    cursor: pointer;
    margin: 12px 0;
}

.image:hover .cover {
    opacity: 1;
}

.vinylSection {
    width: 100%;
    min-height: 100vh;
    background-color: #B42B33;
    color: white;
    text-align: center;
    padding: 5%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vinylSection img {
    height: 320px;
}

.navBar {
    width: 100%;
    height: 180px;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    box-sizing: border-box;
    font-family: "Akira";
    font-size: 14px;
    background-color: transparent;
    z-index: 1;
}

.navBar img {
    width: 80px;
}

@media(max-width:2560px) {

    .borderedDiv .bottom {
        bottom: -9%;
    }

}

@media(max-width:1840px) {

    .borderedDiv .bottom {
        bottom: -20%;
    }

}

@media(max-width: 1024px) {
    .digitalSection {
        padding: 15% 5% 80px;
    }

    .borderedDiv .bottom {
        bottom: -16%;
    }

    .borderedDiv .left,
    .borderedDiv .right,
    .borderedDiv .centered,
    .borderedDiv .bottom {
        font-size: 24px;
    }

}


@media(max-width: 800px) {

    .digitalSection {
        padding-top: 120px;
        padding-bottom: 80px;
    }

    .vinylSection img {
        width: 100%;
        height: auto;
    }
}


@media(max-width: 600px) {
    .navBar {
        height: 80px;
    }

    .title {
        font-size: 24px;
    }

    .subtitle {
        font-size: 16px;
    }

    .imgsBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .vinylSection img {
        width: 100%;
        height: auto;
    }

    .navBar img {
        width: 40px;
    }
}